/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-08 10:59:56
 * @Module: 个人中心
 */
<template>
  <div class="personal border">
    <div class="userInfo">
      <div class="avatar item" style="height:80px; ">
        <div class="label">头像：</div>
        <el-avatar :size="58"></el-avatar>
        <div class="btn">设置头像</div>
      </div>

      <div class="name item">
        <div class="label">姓名：</div>
        <div class="value">
          <p>{{user.name||'暂无信息'}}</p>
          <div class="icon" :class="user.name?'success':'error'"></div>
        </div>
      </div>
      <div class="phone item">
        <div class="label">账号：</div>
        <div class="value">{{user.phone||'-'}}</div>
      </div>
      <div class="createdAt item">
        <div class="label">注册时间：</div>
        <div class="value ">{{user.createdAt|timeFormat('yyyy-mm-dd hh:MM')}}</div>
        <div class="btn pointer" @click="openPasswordDialog">设置密码</div>
      </div>

    </div>
    <div class="line2"></div>
    <div class="title">所属企业</div>
    <div class="companyInfo">
      <div class="company item">
        <div class="label">所在企业：</div>
        <div class="value">
          <p>{{user.company||'暂无信息'}}</p>
          <div class="icon" :class="user.name?'success':'error'"></div>
        </div>
      </div>
      <div class="creator item">
        <div class="label">我的角色：</div>
        <div class="value">
          <p v-if="user.creator" style="color:#FF8412">法人</p>
          <p v-else style="color:#0062FF">员工</p>
        </div>
      </div>
    </div>
    <div class="line2"></div>
    <div class="title">地址管理</div>
    <div class="address">
      <div class="address-name item">
        <div class="label">收件人：</div>
        <div class="value">{{data.name||'--'}}</div>
        <div class="btn pointer" @click="openDialog">修改地址</div>
      </div>
      <div class="phone item">
        <div class="label">手机号码：</div>
        <div class="value">{{data.phone||'--'}}</div>
      </div>
      <div class="address item">
        <div class="label">详细地址：</div>
        <div class="value">{{data.address||'--'}}</div>
      </div>
    </div>
    
    <el-dialog title="修改地址" :close-on-click-modal="false" :visible.sync="dialogVisible" width="40%">
      <el-form label-position="top" label-width="80px" :model="form" size="mini">
        <el-form-item label="收件人姓名：">
          <el-input v-model="form.name" placeholder="输入收件人姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input v-model="form.phone" placeholder="输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="详细地址：">
          <el-input v-model="form.address" placeholder="输入详细地址"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="putCompanyAddress">确 定</el-button>
      </span>
    </el-dialog>
    <settingPassword ref="settingPasswordRef"/>
  </div>
</template>
 <script>
import { mapState } from "vuex";
import settingPassword from "./settingPassword"
export default {
  components: {settingPassword},
  data () {
    return {
      dialogVisible: false,
      data: {
        name: '',
        phone: '',
        address: ''
      },
      form: {
        name: '',
        phone: '',
        address: ''
      },
      showCompanyList: false,
      companyList: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      balance: state => state.user.balance
    })
  },
  mounted () {
    this.$store.dispatch('user/getAccount');
    this.getCompanyAddress();
    this.getAccountColleagueList()
  },
  methods: {
    openPasswordDialog(){
      this.$refs.settingPasswordRef.show()
    },
    toAccountAuth () {
      this.$store.dispatch('user/toAccountAuth')
    },
    toAccountCompanyAuth () {
      this.$store.dispatch('user/toAccountCompanyAuth')
    },
    async getCompanyAddress () {
      const { companyUuid } = this
      const { status, data } = await this.$api.getCompanyAddress({ companyUuid })
      if (status === 200) {
        this.data = data;
      }
    },
    openDialog () {
      this.dialogVisible = true;
      this.form = { ...this.data };
    },
    async getAccountColleagueList () {
      const { data, status } = await this.$api.getAccountColleagueList()
      if (status === 200)
        this.companyList = data;
    },
    async putCompanyAddress () {
      const { companyUuid, form } = this
      const { status, info } = await this.$api.putCompanyAddress({ companyUuid, ...form })
      if (status === 200) {
        this.$message({
          type: 'success',
          message: info
        })
        this.data = { ...form }
        this.dialogVisible = false;
      } else {
        this.$message({
          type: 'error',
          message: info
        })
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.personal {
  padding-bottom: 30px;
  .line2 {
    background: linear-gradient(
      to left,
      transparent 0%,
      transparent 50%,
      #eee 50%,
      #eee 100%
    );
    background-size: 10px 1px;
    background-repeat: repeat-x;
    height: 1px;
    margin: 0 20px;
  }
  .title {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: #262626;
  }
  .btn {
    width: 84px;
    background: #ffffff;
    /* 字体/999999 */
    border: 1px solid #999999;
    border-radius: 2px;
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    color: #999999;
    text-align: center;
    right: 20px;
    position: absolute;
  }
  .avatar {
    .btn {
      color: #0062ff;
      border-color: #0062ff;
    }
  }
  .item {
    display: flex;
    padding: 0 20px;
    line-height: 44px;
    align-items: center;
    position: relative;
    .label {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
    .value {
      font-family: "Source Han Sans CN";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }
  }
  .name,
  .company {
    .value {
      display: flex;
      align-items: center;
      .error {
        background-image: url(~@/static/image/weirenzheng.png);
        background-size: 100%;
      }
      .success {
        background-image: url(~@/static/image/yirenzheng.png);
        background-size: 100%;
      }
      .icon {
        width: 60px;
        height: 20px;
        margin-left: 6px;
        background-repeat: no-repeat;
        background-size: 60px 20px;
      }
    }
  }
}
</style>