<template>
  <div class="personnelSelection">
    <el-dialog
      destroy-on-close
      :close-on-click-modal="false"
      :title="title"
      :visible="dialogVisible"
      width="450px"
      @close="hinde"
    >
      <el-form :model="form" ref="form" @submit.native.prevent size="medium">
        <el-form-item
          prop="password"
          label="密码"
          style="margin-bottom: 10px"
          :rules="passwordRules"
        >
          <el-input
            type="password"
            show-password
            clearable
            v-model="form.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="confirmPassword"
          label="确认密码"
          style="margin-bottom: 10px"
          :rules="confirmPasswordRules"
        >
          <el-input
            type="password"
            show-password
            clearable
            v-model="form.confirmPassword"
            placeholder="请确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="verifyCode"
          label="验证码"
          :rules="{ required: true, message: '验证码不能为空' }"
        >
          <el-input
            type="text"
            v-model="form.verifyCode"
            auto-complete="off"
            placeholder="请输入验证码"
          >
            <i
              slot="suffix"
              style="font-size: 14px; color: #0062ff; margin-right: 15px"
              @click="getcode"
              >{{ secondrender }}</i
            >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="height: 32px">
        <el-button @click="hinde" :loading="loading" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import second from "@/mixins/second.js";
import { mapState, mapGetters } from "vuex";
export default {
  mixins: [second],
  components: {},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        password: "",
        confirmPassword: "",
        verifyCode: "",
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user
    }),
    title() {
      if (this.user.hasPassword) {
        return "修改密码";
      }
      return "设置密码";
    },
    confirmPasswordRules() {
      return [
        { required: true, message: "确认密码不能为空" },
        {
          validator: (rule, value, callback) => {
            if (value === "") {
              callback(new Error("请再次输入密码"));
            } else if (value !== this.form.password) {
              callback(new Error("两次输入密码不一致!"));
            } else {
              callback();
            }
          },
        },
      ];
    },
    passwordRules() {
      return {
        validator: (rule, value, callback) => {
          if (value === "") {
            callback(new Error("密码不能为空"));
          } else if (value.length < 8 || value.length > 16) {
            callback(new Error("密码长度必须在8-16位之间!"));
          } else {
            callback();
          }
        },
      };
    },
  },
  mounted() {},
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hinde() {
      this.dialogVisible = false;
      this.$refs.form.clearValidate();
    },
    submit() {
      // console.log(this.list)
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.putAccountSetPassword();
        } else {
          return false;
        }
      });
    },
    async getcode() {
      if (this.second !== 0) {
        return;
      }
      const { status, info } = await this.$api.postCodeSetPassword({
        phone: this.user.phone,
      });
      if (status == 200) {
        this.$message({
          message: info,
          type: "success",
        });
        this.secondrun(60);
      } else {
        this.$message({
          message: info,
          type: "error",
        });
      }
    },
    async putAccountSetPassword() {
      this.loading = true;
      try {
        const { status, info } = await this.$api.putAccountSetPassword(this.form);
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.hinde();
          this.getAccount();
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
